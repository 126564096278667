import React, { useEffect, useState } from 'react'
import './Navbar.css'
import logo from '../../img/logo.png'
import sms from '../../img/sms.png'
import { Col, Container } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import call from '../../img/call.png'
import { motion } from 'framer-motion'
import { HashLink as Link } from 'react-router-hash-link'
import Ellipse759 from '../../img/Ellipse759.png'
import menu from '../../img/Cart.png'
import callcalling from '../../img/call-calling3 .png'
import mail from '../../img/mail1.png'
export const Navbar = () => {
  const [showHideMobileMenu, setShowHideMobileMenu] = useState(false)
  // useEffect(() => {
  //   alert(showHideMobileMenu)
  // }, [showHideMobileMenu])

  return (
    <Container
      fluid
      className="  navbar-wrapper"
      style={{ overflow: 'hidden' }}
    >
      {showHideMobileMenu && <div className="offCanvas" />}
      <Row className="nav-row">
        {showHideMobileMenu || (
          <Col sm={5} className="col1">
            <div className="mail">
              <span>
                <img src={sms} alt="" />
              </span>
              <span>info@ayat-app.com</span>
            </div>
            <div className="number">
              <span>
                <img src={call} alt="" />
              </span>
              <span>+971 54 255 5000</span>
            </div>
          </Col>
        )}

        <Col sm={2} className="col2">
          <img src={logo} alt="" />
        </Col>
        <Col sm={5} className="col3">
          <div className="language">
            <select>
              <option>English</option>
            </select>
          </div>
          <Link
            to="/#yourAnchorTag"
            spy={true}
            smooth={true}
            style={{
              textDecoration: 'none',
            }}
          >
            <motion.div whileTap={{ scale: 0.9 }} className="button">
              <button>Get in touch</button>
            </motion.div>
          </Link>
          <div className="menu">
            <motion.button
              whileTap={{ scale: 0.9 }}
              className="circle"
              onClick={() => setShowHideMobileMenu(!showHideMobileMenu)}
            >
              {showHideMobileMenu === true ? (
                <img src={menu} />
              ) : (
                <img src={Ellipse759} />
              )}
            </motion.button>
          </div>
        </Col>
      </Row>
      {showHideMobileMenu && (
        <div className="offCanvasContent">
          <Col sm={12} className="col1Off">
            <div className="mailOff">
              <span>
                <img src={mail} alt="" />
              </span>
              <span>info@ayat-app.com</span>
            </div>
            <div className="numberOff">
              <span>
                <img src={callcalling} alt="" />
              </span>
              <span>+971 54 255 5000</span>
            </div>
          </Col>
          <motion.button className="offButton" whileTap={{ scale: 0.9 }}>
            Get in touch
          </motion.button>
        </div>
      )}
    </Container>
  )
}
