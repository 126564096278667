import React from 'react'
import './Cards.css'
export const Cards = ({ image, heading }) => {
  return (
    <div className="cards">
      <img src={image} alt="" />
      <span>{heading}</span>
    </div>
  )
}
