import React from 'react'
import './Hero.css'
import '../../components/Navbar/Navbar'
import { Col, Container, Row } from 'react-bootstrap'
import { motion } from 'framer-motion'
import HERO from '../../img/HERO.png'
export const Hero = () => {
  return (
    <Container fluid className="hero-wrapper">
      <Row className="hero-row">
        <Col sm={6} className="hero-left">
          <div className="hero-title">
            <div className="title">
              <h2>
                creating digital products that illuminate the path to business
                success
              </h2>
            </div>
            <div className="desc">
              <h5>
                we provide a full range of services to create best-in-class
                software solutions for your business that perfectly meet your
                needs. Our team specializes in thinking outside the box and
                providing completely customized app & web solutions that deliver
                desired business outcomes
              </h5>
            </div>
          </div>
        </Col>
        <Col sm={6}>
          <motion.div
            whileHover={{ scale: 1 }}
            transition={{ duration: 0.8 }}
            className="hero-right"
          >
            <img src={HERO} alt="" />
          </motion.div>
          <div className="ellipse" />
        </Col>
      </Row>
    </Container>
  )
}
