import React from 'react'
import './Footer.css'
import Icon from '../../img/Icon.png'

export const Footer = () => {
  return (
    <div className="footer">
      <div className="f-content">
        <img src={Icon} />
        <h5>United Arab Emirates and Saudi Arabia, Ajman</h5>

        <hr />

        <div className="copy">
          <p>Copyright @ 2023 Ayat App | All Rights Reserved</p>
        </div>
      </div>
    </div>
  )
}
