import React from 'react'
import { Cards } from '../../components/Cards/Cards'
import './Services.css'
import Website from '../../img/Website.png'
import Technology from '../../img/Technology.png'
import Software from '../../img/Software.png'
import Mobile from '../../img/Mobile.png'
import Product from '../../img/Product.png'
import UX from '../../img/UX.png'
import { Col, Container, Row } from 'react-bootstrap'
import { motion } from 'framer-motion'
export const Services = () => {
  return (
    <Container fluid className="services-wrapper">
      <Row>
        <div className="line" />
        <div className="s-title">Service</div>
      </Row>
      <Row className="s-row2">
        <Col sm={4}>
          <motion.div whileHover={{ scale: 1.05 }} className="card">
            <Cards image={Website} heading={'Website Development'} />
          </motion.div>
        </Col>
        <Col sm={4}>
          <motion.div whileHover={{ scale: 1.05 }} className="card">
            <Cards image={Mobile} heading={'Mobile Development'} />
          </motion.div>
        </Col>
        <Col sm={4}>
          <motion.div whileHover={{ scale: 1.05 }} className="card">
            <Cards image={UX} heading={'UI/UX Design'} />
          </motion.div>
        </Col>
      </Row>
      <Row className="s-row3">
        <Col sm={4}>
          <motion.div whileHover={{ scale: 1.05 }} className="card">
            <Cards image={Technology} heading={'Technology consulting'} />
          </motion.div>
        </Col>
        <Col sm={4}>
          <motion.div whileHover={{ scale: 1.05 }} className="card">
            <Cards image={Product} heading={'Product Management'} />
          </motion.div>
        </Col>
        <Col sm={4}>
          <motion.div whileHover={{ scale: 1.05 }} className="card">
            <Cards image={Software} heading={'Software Testing'} />
          </motion.div>
        </Col>
      </Row>
    </Container>
  )
}
