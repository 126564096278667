import logo from './logo.svg'
import './App.css'
import { Navbar } from './components/Navbar/Navbar'
import { Hero } from './Sections/Hero/Hero'
import { Services } from './Sections/Services/Services'
import { Contact } from './Sections/Contact/Contact'
import { Footer } from './Sections/Footer/Footer'
import { Route, Routes } from 'react-router-dom'
function App() {
  return (
    <div className="App">
      <Navbar />

      <div className="back">
        <div className="ellipse">
          <Hero />
        </div>
      </div>
      <Services />

      <Footer />
    </div>
  )
}

export default App
